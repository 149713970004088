import { v4 as uuidv4 } from 'uuid';

const SESSION_TTL = 1800; // 30 min

export interface UserUUIDService {
  generateUserUUID(): string;
  getUserUUID(): string;
}

export function userUUIDService(storageService: Storage) {
  function getUserUUID(): string {
    // Check if uuid exists in LocalStorage
    let userUUID = storageService.getItem('vimUserUUID');
    const userUUIDCreatedTime: any = storageService.getItem('vimUserUUIDCreatedTime');
    if (userUUID && userUUIDCreatedTime > Date.now() - SESSION_TTL * 1000) {
      return userUUID;
    }

    // Generate user uuid and save to LocalStorage
    userUUID = uuidv4();
    storageService.setItem('vimUserUUID', userUUID!);
    storageService.setItem('vimUserUUIDCreatedTime', String(Date.now()));
    return userUUID!;
  }
  function generateUserUUID() {
    return uuidv4();
  }
  return {
    generateUserUUID,
    getUserUUID,
  };
}
