export default (): Promise<void> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window.Vim) {
        resolve();
        clearInterval(interval);
      }
    });
  });
};
