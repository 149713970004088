import { FilterOptions, SearchOptions } from './interfaces';
import { safeLocalStorage } from './utils/safe-storage';
import { userUUIDService } from './utils/uuid';
import waitForSdkInitialized from './utils/waitForSdkInitialized';

let vim: any;
let currentApiKey: string;
let currentEnv: string;

declare global {
  interface Window {
    Vim: any;
  }
}

export const initSearchSdk = async (apiKey: string, env: string): Promise<any> => {
  if (vim && apiKey === currentApiKey && env === currentEnv) return Promise.resolve(vim);

  currentApiKey = apiKey;
  currentEnv = env;

  const sdkConfig = JSON.stringify({
    apiKey,
    env,
  });
  localStorage.setItem('sdkConfig', sdkConfig);

  await waitForSdkInitialized();

  if (window.Vim) {
    return window
      .Vim(apiKey, { env, supportedWidgets: ['SCHEDULING', 'SEARCH_AND_ACTION'] })
      .then((instance: any) => {
        vim = instance;
        return vim;
      })
      .catch(() => {
        return Promise.reject(new Error('Vim init error'));
      });
  }

  return Promise.reject(new Error('there is no Vim script'));
};

export const runSdk = async (options: SearchOptions) => {
  const userUUID = userUUIDService(safeLocalStorage);

  const {
    analyticsId,
    areYouSureText,
    category,
    confirmationText,
    cpt,
    displayCopyButton,
    headerTitle,
    icd,
    insurer,
    mainDescription,
    mainTitle,
    memberPhoneNumber,
    memberToken,
    plan,
    selectButtonText,
    term,
  } = options;

  vim.selectProvider(
    {
      searchTerm: term && category ? { term, category } : null,
      geo: calculateGeo(options),
      memberToken,
      plan,
      insurer,
      filters: calculateFilters(options),
      icd: parseCode(icd),
      cpt: parseCode(cpt),
    },
    () => {},
    {
      memberToken,
      widgetSize: 'LARGE',
      displayCopyButton: displayCopyButton || false,
      portal: true,
      memberPhoneNumber,
      analyticsId: analyticsId ?? userUUID.getUserUUID(),
    },
    {
      headerTitle,
      mainTitle,
      mainDescription,
      selectButtonText,
      areYouSureText,
      confirmationText,
    },
  );
};

const calculateGeo = (options: SearchOptions) => {
  const { latitude, longitude, zip, address } = options;

  if (latitude && longitude)
    return {
      geocode: {
        latitude: window.parseFloat(latitude),
        longitude: window.parseFloat(longitude),
      },
    };
  if (zip && zip !== '') return { zip };
  if (address && address !== '') return { address };

  return null;
};

const calculateFilters = (options: SearchOptions) => {
  const filters: FilterOptions = [
    'spokenLanguage',
    'gender',
    'distance',
    'taxonomy',
    'state',
  ].reduce((result, currFilterKey) => {
    const currentFilterValue = options[currFilterKey];

    if (currentFilterValue && currentFilterValue !== '') {
      if (currFilterKey === 'distance')
        return {
          ...result,
          [currFilterKey]: window.parseInt(currentFilterValue),
        };
      if (currFilterKey === 'taxonomy') {
        return { ...result, [currFilterKey]: currentFilterValue.replace(/\s+/g, '').split(',') };
      }
      return { ...result, [currFilterKey]: currentFilterValue };
    }
    return result;
  }, {});

  if (options.onlyBookableProviders === 'true') filters.onlyBookableProviders = true;

  return filters;
};

function parseCode(str?: string) {
  if (str && str.length) {
    return str.startsWith('[') ? JSON.parse(str) : str;
  }
  return undefined;
}
