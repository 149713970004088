import { useState, useEffect } from 'react';
import { initAnalyticsReporter, AnalyticsInstance } from '@getvim/components-utils-analytics';

import useClientConfig from '../config/useClientConfig';

const APP_NAME = 'vim-search-landing';

export default function useAnalytics() {
  const { VIM_SEGMENT_API_KEY } = useClientConfig();

  const [analyticsReporter, setAnalyticsReporter] = useState<AnalyticsInstance>();

  useEffect(() => {
    if (!VIM_SEGMENT_API_KEY || analyticsReporter) return;

    const analytics = initAnalyticsReporter({
      segmentApiKey: VIM_SEGMENT_API_KEY,
      appName: APP_NAME,
    });
    setAnalyticsReporter(analytics);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VIM_SEGMENT_API_KEY]);

  return analyticsReporter;
}
