import React, { useEffect, useCallback } from 'react';
import qs from 'qs';

import { initSearchSdk, runSdk } from './initSdk';
import useClientConfig from './config/useClientConfig';
import useAnalytics from './hooks/useAnalytics';
import { userUUIDService } from './utils/uuid';
import { safeLocalStorage } from './utils/safe-storage';

const defaultOption: qs.IParseOptions = {
  ignoreQueryPrefix: true,
  allowDots: true,
};

function App() {
  const useQuery = qs.parse(window.location.search, defaultOption);
  const config = useClientConfig();
  const analytics = useAnalytics();

  useEffect(() => {
    if (config.VIM_SDK_SCRIPT_URL && analytics) {
      const script = document.createElement('script');
      script.src = config.VIM_SDK_SCRIPT_URL;
      document.body.appendChild(script);

      initSdk();

      return () => {
        document.body.removeChild(script);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, analytics]);

  const handleSdkEvent = useCallback(
    (message: { data: { event: string; payload: any } }) => {
      if (message.data.event === 'closed' || message.data.event === 'finished') {
        initSdk();
      }
    },
    [analytics],
  );

  useEffect(() => {
    window.addEventListener('message', handleSdkEvent);
    return () => {
      window.removeEventListener('message', handleSdkEvent);
    };
  }, [handleSdkEvent]);

  const initSdk = async () => {
    const { apiKey } = useQuery;

    if (!apiKey) {
      alert(`There is no permission to open this page. Please contact administrator.`);

      return;
    }

    try {
      const env = useQuery.env || 'production';
      await initSearchSdk(apiKey as string, env as string);
      analytics?.track('vim-search-landing-open', {
        memberSessionId: userUUIDService(safeLocalStorage).getUserUUID(),
      });
      changeSdkStyle();
      runSdk({ ...useQuery, apiKey: undefined });
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
      }
      alert(`A problem occurred while running a vim search. Please contact the administrator.`);
    }
  };

  const changeSdkStyle = () => {
    const sdk = document.getElementById('vim-widget-modal2');

    if (sdk) sdk.style.maxWidth = '1440px';
  };

  return <div className="App" />;
}

export default App;
